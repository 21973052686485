import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useState, useEffect} from 'react'
import {Button, Card, Col, Container, Row, Form as BootstrapForm } from 'react-bootstrap'
import {toast, Toaster} from 'react-hot-toast'
import axios, {AxiosResponse} from 'axios'
import {useNavigate} from 'react-router-dom'
import {ChangePasswordModal} from './components/ChangePasswordModal'
import {redirectToAuth} from 'supertokens-auth-react'
import {Helmet} from "react-helmet-async";
import { Formik, Form as FormikForm } from 'formik'
import TenantService, {OrganizationSettings} from '../../modules/tenant/TenantService'


const GeneralPageContent = () => {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false)
  const [orgSettings, setOrgSettings] = useState<OrganizationSettings | null>(null)
  
  const navigate = useNavigate()

  const defaultSettings: OrganizationSettings = {
    allowPublicSignup: false,
    supportEmail: '',
    discordWebhook: ''
  }

  useEffect(() => {
    (async () => {
      setOrgSettings(await TenantService.getTenantSettings())
    })()
  }, [])

  const exportSales = async () => {
    toast.loading('Please wait...', {id: 'exportSales'})

    try {
      const response = await axios.post('/api/v1/export/order')

      toast.success(response.data.message, {
        id: 'exportSales',
        duration: 8000,
      })
    } catch (e: any) {
      toast.error(e.response.data.details.message, {
        id: 'exportSales',
        duration: 5000,
      })
    }
  }

  const onPasswordChange = async () => {
    await redirectToAuth()
  }

  const exportItems = async () => {
    toast.loading('Please wait...', {id: 'exportItems'})

    try {
      const response = await axios.post('/api/v1/export/item')

      toast.success(response.data.message, {
        id: 'exportItems',
        duration: 8000,
      })
    } catch (e: any) {
      toast.error(e.response.data.details.message, {
        id: 'exportItems',
        duration: 5000,
      })
    }
  }

  const submitSettings = async (orgSettings: OrganizationSettings) => {
    setOrgSettings(orgSettings)

    try {
        const response = await axios.patch(
            '/api/v1/organization/update/settings', {
                allow_public_signup: orgSettings.allowPublicSignup,
                support_email: orgSettings.supportEmail,
                discord_webhook: orgSettings.discordWebhook
            }
        )

        toast.success(response.data.message, {
            id: 'updateSettings',
            duration: 8000,
        })
    } catch (e: any) {
      toast.error(e.response.data.details.message, {
        id: 'updateSettings',
        duration: 5000,
      })
    }
  }

  return (
    <>
      <Container>
        <Row className='g-6' style={{'--bs-gutter-x': '2.5rem'} as any}>
          <Col xs={12} md={6}>
            <Card className='h-100'>
              <Card.Header>
                <Card.Title>Resource Export</Card.Title>
              </Card.Header>

              <Card.Body>
                <p className='text-grey'>Easily export all of your item or sale data history</p>

                <p className='text-muted'>
                  Upon a resource export, you'll receive an email with a CSV.
                </p>

                <div className='mt-20 d-flex flex-row justify-content-end'>
                  <Button variant='primary' className='me-5' onClick={() => exportItems()}>
                    {/*<i className='fa fa-square text-secondary px-5'></i>*/}
                    Export Items
                  </Button>

                  <Button variant='primary' onClick={() => exportSales()}>
                    {/*<i className='fa fa-square text-secondary px-5'></i>*/}
                    Export Sales
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6}>
            <Card className='h-100'>
              <Card.Header>
                <Card.Title>Account Security</Card.Title>
              </Card.Header>

              <Card.Body>
                <div className='d-flex flex-row justify-content-between'>
                  <div>
                    <span className='text-grey d-block'>Password</span>
                    <span className='text-muted'>●●●●●●●●●●●●●●●●</span>
                  </div>

                  <Button variant={'danger'} onClick={() => setShowChangePasswordModal(true)}>
                    Change Password
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='g-6 mt-2' style={{'--bs-gutter-x': '2.5rem'} as any}>
          <Col xs={12} md={12}>
            <Card className='h-100'>
              <Card.Header>
                <Card.Title>Store Settings</Card.Title>
              </Card.Header>

              <Card.Body>
                <Formik
                  initialValues={orgSettings || defaultSettings}
                  onSubmit={submitSettings}
                  enableReinitialize={true}
                >
                  {({ handleSubmit, values, dirty, setFieldValue }) => (
                    <FormikForm>
                      <div className='row mb-5'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                          <span>Consigner Sign-Up Type</span>
                        </label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <BootstrapForm.Select 
                            value={values.allowPublicSignup.toString()}
                            onChange={(e) => {
                              const value = e.target.value === 'true'
                              setFieldValue('allowPublicSignup', value)
                            }}
                            className='form-select form-select-solid'
                          >
                            <option value="true">Public</option>
                            <option value="false">Invite</option>
                          </BootstrapForm.Select>
                        </div>
                      </div>
                      <div className='row mb-5'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                          <span>Store Support Email</span>
                        </label>
                        <div className='col-lg-8 d-flex align-items-center'>
                            <BootstrapForm.Control 
                                type='text'
                                placeholder='support@acme.com'
                                value={values.supportEmail || ''}
                                onChange={(e) => setFieldValue('supportEmail', e.target.value)}
                                className='form-control-solid'
                            />
                        </div>
                      </div>
                      <div className='row mb-5'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                          <span>Discord Webhook URL</span>
                        </label>
                        <div className='col-lg-8 d-flex align-items-center'>
                            <BootstrapForm.Control 
                                type='text'
                                placeholder='https://discord.com/api/webhooks/...'
                                value={values.discordWebhook || ''}
                                onChange={(e) => setFieldValue('discordWebhook', e.target.value)}
                                className='form-control-solid'
                            />
                        </div>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <Button
                          variant='secondary'
                          className='mt-5'
                          type='submit'
                          disabled={!dirty}
                        >
                          {!dirty ? 'No Changes' : 'Update Settings'}
                        </Button>
                      </div>
                    </FormikForm>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <ChangePasswordModal
        visible={showChangePasswordModal}
        onConfirm={onPasswordChange}
        onCancel={() => setShowChangePasswordModal(false)}
      />
    </>
  )
}

const GeneralPageWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.GENERAL'})}</PageTitle>
      <Helmet>
        <title>{intl.formatMessage({id: 'MENU.GENERAL'})} - Bind</title>
      </Helmet>
      <GeneralPageContent />
    </>
  )
}

export default GeneralPageWrapper;
