import {ConfirmModal, PaginatedTable, TableHeader, PaginatedTableComponentHandles} from "components";
import Card from "../../modules/dashboard/dashboard/components/Card";
import React, {useState, useRef} from "react";
import TableUtilities from "components/dist/tables/table-utilities";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Consignor} from "../../modules/dashboard/consignors/consignors.service";
import {PageTitle} from "../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {Helmet} from "react-helmet-async";
import CreateConsignorModal from "../../modules/dashboard/consignors/components/CreateConsignorModal";


const ConsignorsPage = () => {
    const [selectedConsignors, setSelectedConsignors] = useState<Consignor[]>([])
    const [show, setShow] = useState(false);
    const tableRef = useRef<PaginatedTableComponentHandles | null>(null);

    const handleConsignorCreated = () => {
        tableRef?.current?.loadData()
        setShow(false)
    }

    const headers: TableHeader[] = [
        {
            key: 'fullName',
            label: 'Name',
            rowClassName: 'min-w-125px',
            format: (cell) => <Link className='text-white' to={`${cell.row.id}`}>{cell.item}</Link>
        },
        {key: 'email', label: 'Email', rowClassName: 'min-w-125px'},
        {
            key: 'phoneNumber', 
            label: 'Phone Number', 
            format: (cell) => cell.item || '-'
        },
        {key: 'userRef', label: 'Reference'},
        {key: 'tier.name', label: 'Tier',},
        {key: 'createdAt', label: 'Joined Date', format: (cell) => TableUtilities.formatDate(cell.item)},
        {key: 'status', label: 'Status', format: (cell) => TableUtilities.statusBadge(cell.item)},
    ]

    const onItemsSelected = (items: any[]) => {
        setSelectedConsignors(items as Consignor[]);
    }

    const tableActions = () => (
        <>
            <Button
                variant='primary'
                onClick={() => setShow(true)}
            >
                Create Consigner
            </Button>
        </>
    )

    const intl = useIntl()

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CONSIGNORS'})}</PageTitle>

            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.CONSIGNORS'})} - Bind</title>
            </Helmet>

            <Card className={'pt-13'} title=''>
                <PaginatedTable
                    className={''}
                    paginate={true}
                    dataProperty={'consignors'}
                    headers={headers}
                    actions={tableActions()}
                    onSelected={onItemsSelected}
                    selectedItems={selectedConsignors}
                    dataUrl={'/api/v1/user/consignor/all'}
                    filterLocally={true}
                />
                <CreateConsignorModal
                    show={show}
                    onClose={() => setShow(false)}
                    onConsignorCreated={handleConsignorCreated}
                />
            </Card>
        </>
    )
}

export default ConsignorsPage