import axios from "axios";

const ConsignorsService = {
    fetchConsignor: async  (consignorId: string) : Promise<Consignor> => {
        const response = await axios.get(`/api/v1/user/consignor/view/${consignorId}`)

        return response.data;
    },
    fetchConsignors: async (): Promise<Consignor[]> => {
        const response = await axios.get('/api/v1/user/consignor/all');

        return response.data.consignors
    },
    fetchTiers: async (): Promise<Tier[]> => {
        const response = await axios.get('/api/v1/tier/all')

        return response.data.tiers
    },
    updateConsignorTier: async (consignorId: string, tierId: string) => {
        return await axios.patch('/api/v1/user/update/tier', {
            'consignor_id': consignorId,
            'tier_id': tierId
        })
    },
    createConsignor: async (
        email: string,
        phoneNumber: string,
        firstName: string,
        lastName: string,
        tierId: string
    ) => {
        const response = await axios.post('/api/v1/user/consignor/create', {
            email: email,
            phone_number: phoneNumber,
            full_name: `${firstName} ${lastName}`,
            tier_id: tierId
        })

        return response;
    }
}

export interface Consignor {
    id: string;
    createdAt: string;
    email: string;
    phoneNumber?: string;
    userRef: string;
    firstName: string;
    lastName: string;
    fullName: string;
    status: string;
    userType: string;
    tier: Tier;
}

export interface Tier {
    name: string;
    percent: number;
    minimumFee: number;
    organizationId: string;
    default: boolean;
    id: string;
    createdAt: string;
}



export default ConsignorsService;