import axios from "axios";


export type OrganizationSettings = {
    allowPublicSignup: boolean;
    supportEmail: string;
    discordWebhook: string;
}

const TenantService = {
    getTenantSettings: async (): Promise<OrganizationSettings> => {
        const response = await axios.get('/api/v1/organization/settings');

        return response.data as OrganizationSettings;
    }
}


export default TenantService;